<template>
	<div>
		<div class="header">
			<img src="assets/logo.png"/>
			<!-- <div class="g-signin2" data-onsuccess="onSignIn"></div> -->
			<br />
			{{ loggedIn ? "Logged In" : "Logged Out" }}
			<button @click="handleLogin">Login with Google</button>
		</div>

		<!-- <a href="#" @click="signOut">Sign out</a> -->

		<div class="input">
			<h4 class="">{{ symbol }}</h4>
			<input class="" v-model="appkey" />
			<input class="" v-model="symbol" />
			<input class="" v-model="expirationStart" />
			<input class="" v-model="expirationEnd" />
			<button
				id="submitbutton"
				class="neon"
				@click="getOptions(symbol, appkey, expirationStart, expirationEnd, accessToken)"
			>
				<span v-if="!loading">Submit</span>
				<span v-else> <span class="spinner"></span> Loading... </span>
			</button>
		</div>
		<div class="dashboard">
			<div class="chart">
				<VannaChart :optionData="optionData" />
			</div>
			<div class="chart">
				<VolatilityChart :optionData="optionData" />
			</div>
			<div class="chart">
				<PriceChart :optionData="optionData" />
			</div>
			<div class="chart">
				<ContractPremiumChart :optionData="optionData" />
			</div>
			<div class="chart">
				<ExtrinsicValueChart :optionData="optionData" />
			</div>
			<div class="chart">
				<IntrinsicValueChart :optionData="optionData" />
			</div>
			<div class="chart">
				<DeltaChart :optionData="optionData" />
			</div>
			<!-- <div class="chart">
        <DChart :optionData="optionData"/>
      </div> -->
			<div class="chart">
				<ThetaChart :optionData="optionData" />
			</div>
			<div class="chart">
				<GammaChart :optionData="optionData" />
			</div>
			<div class="chart">
				<VolumeChart :optionData="optionData" />
			</div>
			<div class="chart">
				<MaxPainChart :optionData="optionData" />
			</div>
			<div class="chart">
				<RhoChart :optionData="optionData" />
			</div>
			<div class="chart">
				<OpenInterestChart :optionData="optionData" />
			</div>
			<div class="chart">
				<ThetaGammaChart :optionData="optionData" />
			</div>
			<div class="chart">
				<VegaChart :optionData="optionData" />
			</div>
			<div class="chart">
				<LambdaChart :optionData="optionData" />
			</div>
			<div class="chart">
				<VommaChart :optionData="optionData" />
			</div>
		</div>
	</div>
</template>

<style>
.header {
	padding: 60px;
	text-align: center;
	background: #1abc9c;
	color: white;
	font-size: 30px;
}

body {
	background: lightslategrey;
	color: white;
}
.dashboard {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-template-rows: repeat(6, 1fr);
	gap: 1rem;
	margin: 1rem;
}

.chart {
	grid-column: span 1;
	grid-row: span 1;
	border: 1px solid greenyellow;
	box-shadow: 0 0 5px greenyellow;
	transition: box-shadow 0.3s ease-in-out;
}

.chart:hover {
	box-shadow: 0 0 15px greenyellow;
}

input,
button {
	font-size: 1.2rem;
	padding: 0.5rem;
	border-radius: 0.5rem;
	border: none;
	box-shadow: 0 0 5px #fff;
	transition: box-shadow 0.3s ease-in-out;
}
input:focus,
button:focus {
	outline: none;
}
input {
	background-color: rgba(0, 0, 0, 0.2);
	color: white;
}
button {
	background-color: #fff;
	color: black;
}
button:hover {
	cursor: pointer;
	box-shadow: 0 0 10px #fff;
}
.spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 5px solid rgba(255, 255, 255, 0.3);
	border-top: 5px solid #fff;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 0.69s linear infinite;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.neon {
	color: #fff;
	text-shadow: 0 0 3px #fff, 0 0 5px #fff, 0 0 8px #fff, 0 0 10px blue,
		0 0 15px blue, 0 0 20px blue, 0 0 25px blue;
}
.input {
	margin: 0;
	padding: 0;
}

.neon-input {
	position: relative;
	margin-bottom: 0.5rem;
}
</style>
<script>
/* eslint-disable */

import ContractPremiumChart from "./components/ContractPremiumChart.vue";
import DChart from "./components/DChart.vue";
import DeltaChart from "./components/DeltaChart.vue";
import ExtrinsicValueChart from "./components/ExtrinsicValueChart.vue";
import GammaChart from "./components/GammaChart.vue";
import IntrinsicValueChart from "./components/IntrinsicValueChart.vue";
import LambdaChart from "./components/LambdaChart.vue";
import MaxPainChart from "./components/MaxPainChart.vue";
import OpenInterestChart from "./components/OpenInterestChart.vue";
import PriceChart from "./components/PricesChart.vue";
import RhoChart from "./components/RhoChart.vue";
import ThetaGammaChart from "./components/Theta-Gamma.vue";
import ThetaChart from "./components/ThetaChart.vue";
import VannaChart from "./components/VannaChart.vue";
import VolumeChart from "./components/VolumeChart.vue";
import VolatilityChart from "./components/VolatilityChart.vue";
import VegaChart from "./components/VegaChart.vue";
import VommaChart from "./components/VommaChart.vue";
import axios from "axios";
import Slider from "./components/SingleSlider.vue";

export default {
	name: "App",
	components: {
		PriceChart,
		ContractPremiumChart,
		RhoChart,
		GammaChart,
		OpenInterestChart,
		ThetaChart,
		ThetaGammaChart,
		VegaChart,
		VolumeChart,
		Slider,
		LambdaChart,
		MaxPainChart,
		DChart,
		DeltaChart,
		ExtrinsicValueChart,
		IntrinsicValueChart,
		VommaChart,
		VannaChart,
		DeltaChart,
		ExtrinsicValueChart,
		VolatilityChart,
	},
	data() {
		const d1 = new Date().toISOString().split("T")[0];
		const d2 = new Date(
			Date.now() +
				69 * //(730.5 *
					86400000 // 24 hours
		)
			.toISOString()
			.split("T")[0];

		return {
			optionData: null,
			symbol: "SPY",
			appkey: "",
			expirationStart: d1,
			expirationEnd: d2,
			loggedIn: false,
			accessToken: null,
			loading: false, // add a loading state to track whether the request is ongoing
		};
	},

	methods: {
		async handleLogin() {
			// Authenticate the user with Google Sign-In
			const authResult = await gapi.auth2.getAuthInstance().signIn();

			// Exchange the ID token for an access token
			const response = await axios.post("https://oauth2.googleapis.com/token", {
				code: authResult.code,
				client_id:
					"527093411439-846lool85pr7v5505qjj4k1ls71j9ptj.apps.googleusercontent.com",
				client_secret: "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",
				redirect_uri: "postmessage",
				grant_type: "authorization_code",
			});

			// Store the access token for later use
			this.accessToken = response.data.access_token;
		},
		async getOptions(symbol, appkey, expirationStart, expirationEnd, token) {
			const baseURL = "https://thehttpfunctionapp.azurewebsites.net/";

			let params = {
				Symbol: symbol,
				ExpirationStart: expirationStart,
				ExpirationEnd: expirationEnd,
			};
			const options = {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				withcredentials: true,
			};
			this.loading = true; // set loading state to true before the request

			try {
				const response = await axios.get(
					baseURL +
						"api/Function1?code=" + appkey + "&symbol=" +
						params.Symbol +
						"&exp1=" +
						params.ExpirationStart +
						"&exp2=" +
						params.ExpirationEnd +
						"&dte=100",
					options
				);
				this.optionData = response.data;
			} catch (error) {
				console.error(error);
			}

			this.loading = false; // set loading state back to false after the response is received
		},
	},
};
</script>
