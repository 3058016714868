<template>
	<div>
		<h3>Max Pain</h3>
		<canvas id="maxPainChart"></canvas>
	</div>
</template>

<script>
/* eslint-disable */
// import chartjs from "chart.js";
import { Chart } from "chart.js";
//import crosshair from "chartjs-plugin-crosshair"
import moment from "moment";
export default {
	name: "MaxPainChart",
	props: {
		optionData: Object,
	},
	watch: {
		optionData(newval, oldval) {
			if (newval !== null) {
				this.formatData();
				this.init();
			}
		},
	},
	data() {
		return {
			chart: {
				type: "line",
				data: {
					labels: [],
					datasets: [],
				},
				options: {
					draggable: false,
					showLine: false,
					spanGaps: true,
					animation: false,
					tooltips: {
						mode: "single",
						intersect: false,
						callbacks: {
							title: function(tooltipItems, data) {
								//return title of tootip
								return data.labels[tooltipItems[0].index];
							},
							label: function(tooltipItem, data) {
								// get the data of the hovered datapoint
								let dataset = data.datasets[tooltipItem.datasetIndex];
								let point = dataset.data[tooltipItem.index];

								// return an array of lines, each line contains the label and value of a property
								let lines = [];
								lines.push(`${dataset.label}:`);
								for (let key in point) {
									if (key !== "x" && key !== "y" && key !== "z") {
										lines.push(`${key}: ${point[key]}`);
									}
								}
								return lines;
							},
						},
					},
					scales: {
						yAxes: [
							{
								id: "A",
								type: "linear",
								position: "left",
							},
						],
						xAxes: [
							{
								type: "linear",
								position: "bottom",
							},
						],
					},
					legend: {
						display: true,
						position: "right",
					},
				},
				hover: {
					mode: "index",
					intersect: false,
				},
			},
		};
	},
	created() {},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			let ctx = document.getElementById("maxPainChart").getContext("2d");
			ctx.fillStyle = "#000000";
			ctx.fillRect(0, 0, 1000, 1000);
			let myChart = new Chart(ctx, this.chart);
		},
		formatData() {
			const yMax = 900;
			const xMax = 0.5;
			let colorIndex = 0;
			const datasets = [];
			const { callExpDateMap, putExpDateMap, underlying } = this.optionData;
			const underlyingPrice = underlying.ask;
			const createDataset = (label, color, yAxisId) => ({
				label,
				data: [],
				yAxisId,
				borderColor: color,
				fill: false,
			});
			const createDataPoint = (
				value,
				delta,
				strike,
				price,
				daysToExpiration
			) => ({
				value: value,
				strike: strike,
				delta: Math.abs(delta),
				last: price,
				x: strike,
				y: value,
				dte: daysToExpiration,
			});

			const processOptionData = (optionType, optionExpDateMap) => {
				const expDateKeys = Object.keys(optionExpDateMap);
				const expDateValues = Object.values(optionExpDateMap);
				let sumOfAllOptionLast = 0;

				for (let i = 0; i < expDateKeys.length; i++) {
					const strikes = expDateValues[i];

					const yAxisId = optionType === "CALL" ? "A" : "B";
					const label = `${optionType}_${expDateKeys[i]}`;
					const dataset =
						optionType === "CALL"
							? createDataset(
									label,
									`rgba(0, ${60 * (i / expDateValues.length) + 60}, ${120 *
										(i / expDateValues.length) +
										120}, ${0.45 * (i / expDateValues.length) + 0.45})`,
									yAxisId
							  )
							: createDataset(
									label,
									`rgba(${120 * (i / expDateValues.length) + 120}, ${60 *
										(i / expDateValues.length) +
										60}, 0, ${0.45 * (i / expDateValues.length) + 0.45})`,
									yAxisId
							  );

					let sumOfDiffs = {};
					for (const strike in strikes) {
						const option = strikes[strike][0] || {};
						const optionSymbol = option.symbol;

						
					// Find the strike price at which the sum of the absolute values of the differences between the call and put options' open interest is greatest
					let maxPain = 0;
					let maxPainStrike = null;
					for (const strike in sumOfDiffs) {
						// console.log(strike, sumOfDiffs[strike]);
						const absDiff = Math.abs(sumOfDiffs[strike]);
						if (absDiff > maxPain) {
							maxPain = absDiff;
							maxPainStrike = strike;
							// console.log(maxPainStrike);
						}
					}
					if (maxPainStrike !== null) {
						// console.log(maxPainStrike);
						dataset.maxPain = parseFloat(maxPainStrike);
					}

						if (
							optionType === "CALL" &&
							!option.inTheMoney &&
							Math.abs(option.delta) < 1
						) {
							const dataPoint = createDataPoint(
								dataset.maxPain,
								option.delta,
								strike,
								option.last,
								expDateKeys[i].slice(-2)
							);
							dataset.data.push(dataPoint);
							if (!sumOfDiffs[strike]) {
								sumOfDiffs[strike] = 0;
							}
							sumOfDiffs[strike] += option.openInterest;
						}
						if (
							optionType === "PUT" 
							// &&
							// !option.inTheMoney &&
							// Math.abs(option.delta) < 1
						) {
							const dataPoint = createDataPoint(
								dataset.maxPain,
								option.delta,
								strike,
								option.last,
								expDateKeys[i].slice(-2)
							);
							dataset.data.push(dataPoint);
							if (!sumOfDiffs[strike]) {
								sumOfDiffs[strike] = 0;
							}
							sumOfDiffs[strike] -= option.openInterest;
						}
					}

					datasets.push(dataset);
				}
			};

			// 'rgba(80, 140, 200, 0.99)', 'rgba(50, 100, 150, 0.99)', 'rgba(20, 60, 100, 0.99)']
			processOptionData("CALL", callExpDateMap);
			processOptionData("PUT", putExpDateMap);

			this.chart.data.datasets = datasets;
		},
	},
};
</script>
