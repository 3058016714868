<template>
  <div>
    <canvas id="dChart"></canvas>
  </div>
</template>


<script>
/* eslint-disable */
import * as d3 from "d3";

export default {
  name: "DChart",
  props: {
    optionData: Object,
  },
  watch: {
    optionData(newval, oldval) {
      if (newval !== null) {
        this.formatData();
        this.init();
      }
    },
  },
  data() {
    return {
      chart: {
        type: "line",
        data: {
          labels: [],
          datasets: [
            {
              label: "Put Delta",
              data: [],
              yAxisID: 'A',         
              borderColor: 'rgba(255, 99, 132, 0.8)',
              fill: false
            },
            {
              label: "Put Last",
              data: [],
              yAxisId: 'A',
              borderColor: 'rgba(255, 206, 86, 0.8)',
              fill: false
            },
            {
              label: "Call Delta",
              data: [],
              yAxisID: 'A',
              borderColor: 'rgba(54, 162, 235, 0.8)',
              fill: false
            },
            {
              label: "Call Last",
              data: [],
              yAxisId: 'A',
              borderColor: 'rgba(54, 262, 235, 0.8)',
              fill: false
            },
          ],
        },
        options: {
          showLine: false,
          spanGaps: true,
          animation: false,
          tooltips: {
            mode: 'index',
            intersect: false
          },
          scales: {
            yAxes: [{
              id: 'A',
              type: 'linear',
              position: 'left'
            }],
            xAxes: [
              {
                type: "linear",
                                position: "bottom",
              },
            ],
          },
          legend: {
            display: true,
            position: 'right',
            labels: {
              fontColor: 'white',
            }
          },
        },
          hover: {
          mode: 'index',
          intersect: false
        }
      },
    };
  },
  created() {
  },
  mounted() {
    this.init();   
  },
  methods: {
    init() {
      // Create the svg area
      var svg = d3.select("#GFG")
          .append("svg")
          .attr("width", 300)
          .attr("height", 300)
          .append("g")
          .attr("transform", "translate(150,150)");
  
      // Create input data
      var data = [[ 51, 148, 60, 71, 34, 5, 1],
                  [ 10, 161, 80, 80, 12, 34, 53],
                  [175,  71, 16, 68, 0, 0, 3],
                  [ 13, 990,  9, 69, 76, 35, 4],
                  [ 51, 148, 60, 71, 34, 5, 1]];
  
      // Give this matrix to d3.chord()
      var chords = d3.chord()
          .padAngle(0.2) 
          .sortSubgroups(d3.descending)
          .sortChords(d3.descending)
          (data);
  
      // Use of d3.ribbon() function
      var ribboon = d3.ribbon().radius(140);  
  
      svg.datum(chords)
          .append("g")
          .selectAll("path")
          .data(function (d) { return d; })
          .enter()
          .append("path")
          .attr("d", ribboon)
          .style("fill", "#449db8")
          .style("stroke", "black");

      // const svg = d3
      //   .select("#dChart")
      //   .append("svg")
      //   .attr("width", w)
      //   .attr("height", h);

        const putdelta = this.chart.data.datasets[0].data
        const putlast = this.chart.data.datasets[1].data
        const calldelta = this.chart.data.datasets[2].data
        const calllast = this.chart.data.datasets[3].data

      const color = d3.scaleOrdinal(d3.schemeDark2);

      

    },
    formatData() {
      let putexpdatekeys = Object.keys(this.optionData.putExpDateMap);
      let putexpdatevalues = Object.values(this.optionData.putExpDateMap);
      
       for(const item in putexpdatekeys){
        let putStrikes = putexpdatevalues[item];
        // console.log(putStrikes);
        for (const strike in putStrikes) {
        let p = putStrikes[strike][0];
        
        let putsdelta = {
          d: Math.abs(p.delta),
          x: strike,
          y: Math.abs(p.delta) * p.volume,
          z: p.daysToExpiration
        };
        
        let putslastprice = {
          d: Math.abs(p.delta),
          x: strike,
          y: p.last,
          z: p.daysToExpiration
        };

        this.chart.data.datasets[0].data.push(putsdelta);
        this.chart.data.datasets[1].data.push(putslastprice);
      }

      }
      let callexpdatekeys = Object.keys(this.optionData.callExpDateMap);
      let callexpdatevalues = Object.values(this.optionData.callExpDateMap);
      
       for(const item in callexpdatekeys){
        let callStrikes = callexpdatevalues[item];
        // console.log(callStrikes);
        for (const strike in callStrikes) {
        let c = callStrikes[strike][0];
        
        let callsdelta = {
          d: Math.abs(c.delta),
          x: strike,
          y: Math.abs(c.delta) * c.volume,
          z: c.daysToExpiration
        };
        
        let callslastprice = {
          d: Math.abs(p.delta),
          x: strike,
          y: c.last,
          z: c.daysToExpiration
        };

        this.chart.data.datasets[2].data.push(callsdelta);
        this.chart.data.datasets[3].data.push(callslastprice);
      }

      }

      


    },
  },
};
</script>

